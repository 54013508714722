import React from 'react';
import PropTypes from 'prop-types';
import { XCloseIcon } from '@rsos/assets/icons';
import { Absolute, Box } from '@rsos/components/capstone/base';

const CloseButton = ({
  onClose,
  width = 11,
  height = 11,
  absolute = false,
  top = 'auto',
  right = 'auto',
  bottom = 'auto',
  left = 'auto',
  zIndex = 'auto',
}) =>
  absolute ? (
    <Absolute
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      zIndex={zIndex}
    >
      <Box onClick={onClose} cursor="pointer">
        <XCloseIcon color="secondaryLink" width={width} height={height} />
      </Box>
    </Absolute>
  ) : (
    <Box onClick={onClose} cursor="pointer">
      <XCloseIcon color="secondaryLink" width={width} height={height} />
    </Box>
  );

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  absolute: PropTypes.bool,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CloseButton;
