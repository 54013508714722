import { ExternalLinkIcon } from '@rsos/assets/icons';
import Protected from '@rsos/components/capstone/Layout/Protected';
import { Box, StyledHR } from '@rsos/components/capstone/base';
import Dropdown from '@rsos/components/capstone/base/Dropdown';
import {
  DropdownDialog,
  DropdownBody,
  DropdownList,
  DropdownListItem,
  DropdownFooter,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';
import { Flag } from '@rsos/flags';
import determineDataPath from '@rsos/utils/determineDataPath';
import VersionNumber from '../../../VersionNumber';
import { Circle } from '../../Navigation.styles';
import StyledAuthLink from '../../common/StyledAuthLink';
import StyledSmartLink from '../../common/StyledSmartLink';
import { HamburgerMenu } from '../../common/common.styles.js';
import { mapAdminPermissionsToPath } from '../../common/helpers';
import NewNotification from './NewNotification';
import StyledSamlLink from './StyledSamlLink';

const NavigationControl = ({
  user,
  handleSAMLRequest,
  hasNewDataSource,
  isLoadingSAML,
  handleLogout,
  irpAccessToken,
  isJVenabled,
}) => {
  const { pageAccessList } = user;

  const displayAdminMenuItem = mapAdminPermissionsToPath(pageAccessList);
  const application = (user.currentRole && user.currentRole.application) || '';

  const handleSAMLRequestClick = service => {
    handleSAMLRequest(service);
  };
  const isLoggedIn = user?.isLoggedIn;

  return (
    <Box data-name="navigation-menu" position="relative">
      {displayAdminMenuItem && hasNewDataSource && <Circle />}
      <Dropdown triggerElement={HamburgerMenu}>
        <DropdownDialog top={43} right={6} width="200px">
          <DropdownBody>
            <Protected isLoggedIn={user.isLoggedIn}>
              <DropdownList borderless>
                <StyledSmartLink
                  data-name="navigation-control-emergency-data-link"
                  to={determineDataPath(
                    application,
                    isJVenabled,
                    irpAccessToken,
                    isLoggedIn,
                  )}
                >
                  <DropdownListItem>Data</DropdownListItem>
                </StyledSmartLink>
                {displayAdminMenuItem && (
                  <StyledSmartLink
                    to={displayAdminMenuItem}
                    data-name="navigation-control-admin-link"
                  >
                    <DropdownListItem>
                      Admin{' '}
                      <Box horizontal width="100%" justify="flex-end">
                        <NewNotification hasNewDataSource={hasNewDataSource} />
                      </Box>
                    </DropdownListItem>
                  </StyledSmartLink>
                )}
                {application === 'central_station' ? null : (
                  <>
                    <DropdownListItem>
                      <StyledSamlLink
                        service="support"
                        handleClick={() => handleSAMLRequestClick('support')}
                        isLoadingSAML={isLoadingSAML}
                        withIcon
                        data-name="navigation-control-support-link"
                      />
                    </DropdownListItem>
                    <DropdownListItem>
                      <StyledSamlLink
                        service="training"
                        handleClick={() => handleSAMLRequestClick('training')}
                        isLoadingSAML={isLoadingSAML}
                        withIcon
                        data-name="navigation-control-training-link"
                      />
                    </DropdownListItem>
                    <Flag name="community_link">
                      <DropdownListItem>
                        <Box
                          horizontal
                          justify="space-between"
                          align="center"
                          width="100%"
                        >
                          <StyledSmartLink
                            to="https://rapidsos-community.mn.co"
                            data-name="navigation-control-community"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Community
                          </StyledSmartLink>
                          <ExternalLinkIcon height={12} width={12} />
                        </Box>
                      </DropdownListItem>
                    </Flag>
                    <StyledSmartLink data-name="navigation-control-give-feedback-link">
                      <DropdownListItem>Give Feedback</DropdownListItem>
                    </StyledSmartLink>
                  </>
                )}
                <Box py={10} px={16}>
                  <VersionNumber />
                </Box>
              </DropdownList>
            </Protected>
          </DropdownBody>
          <StyledHR
            borderTop="1px solid"
            borderBottom="0px"
            borderColor="lightLineDivider"
            // margin is here for non-erquy converted apps like Admin where browser overrides props. It is temp
            m={0}
          />
          <DropdownFooter>
            <Box p={10}>
              <StyledAuthLink onClick={handleLogout} />
            </Box>
          </DropdownFooter>
        </DropdownDialog>
      </Dropdown>
    </Box>
  );
};

export default NavigationControl;
