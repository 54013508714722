import { lightModeColorPalette } from './lightModeDefinition';

export const darkerModeColorPalette = {
  transparent: 'transparent',
  primary_base: '#87ccea',
  primary_base_c2: '#daf2fd',
  primary_text: '#d9eaf8',
  secondary_link: '#acc2d5',
  secondary_text: '#96adc2',
  heavy_line_outline: '#5b778d',
  light_line_divider: '#2c455d',
  highlights: '#002e47',
  foreground: '#16222f',
  background: '#0b1928',
  light_warning: '#fdf9d2',
  light_warning_text: '#051e37',
  heavy_warning: '#ffe8cb',
  light_danger: '#ff6f6f',
  heavy_danger: '#e46e6e',
  success: '#30e0ac',
  heavy_success: '#11b484',
  states: '#a2bbf1',
  pane_primary_text: '#30e0ac',
  unseen: '#d599ff',
  caution: '#fff96c',
  tag_success_background: '#09434c',
  tag_caution_background: '#3b4535',
  tag_warning_background: '#4f3d31',
  tag_secondary_background: '#25384c',
};

export default {
  palette: darkerModeColorPalette,
  fieldsToOverride: [
    {
      key: 'modal.scrim',
      value: darkerModeColorPalette.secondary_text,
    },
    {
      key: 'mapCard.disclaimer.bg',
      value: lightModeColorPalette.light_warning,
    },
    {
      key: 'mapCard.disclaimer.floorsIcon',
      value: lightModeColorPalette.secondary_text,
    },
    {
      key: 'mapCard.disclaimer.primaryText',
      value: lightModeColorPalette.primary_text,
    },
    {
      key: 'mapCard.disclaimer.secondaryText',
      value: lightModeColorPalette.secondary_text,
    },
    {
      key: 'massDisasterToast.text',
      value: darkerModeColorPalette.primary_text,
    },
    {
      key: 'trainIncidentAlert.iconColor',
      value: darkerModeColorPalette.background,
    },
    {
      key: 'trainIncidentAlert.iconBg',
      value: '#fede8d',
    },
  ],
};
