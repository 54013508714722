export const lightModeColorPalette = {
  transparent: 'transparent',
  primary_base: '#177AB6',
  primary_base_c2: '#0E4787',
  primary_text: '#2d2d2d',
  secondary_link: '#636363',
  secondary_text: '#636363',
  heavy_line_outline: '#e2e2e2',
  light_line_divider: '#ebebeb',
  highlights: '#daf2fd',
  foreground: '#f7f7f7',
  background: '#ffffff',
  light_warning: '#fdf9d2',
  light_warning_text: '#636363',
  heavy_warning: '#BB5206',
  light_danger: '#E42629',
  heavy_danger: '#900303',
  success: '#00825D',
  heavy_success: '#11B484',
  states: '#5c6e9d',
  pane_primary_text: '#00825D',
  unseen: '#9600ff',
  caution: '#866B00',
  tag_success_background: '#E8F6E7',
  tag_caution_background: '#FFFACC',
  tag_warning_background: '#F8EEE6',
  tag_secondary_background: '#F5F5F5',
};

export default {
  palette: lightModeColorPalette,
  fieldsToOverride: null,
  // example of how to override a field:
  // [
  //   {
  //     key: 'dataTabs.activeBackgroundColor',
  //     value: 'yellow',
  //   },
  // ],
};
