import { useMemo } from 'react';
import { UNITE_EVENT_PANE } from '@rsos/capstone/src/constants/capabilities';
import { UNITE_EVENT_PANE_PERMISSION } from '@rsos/capstone/src/constants/permissionNames';
import { useFlag } from '@rsos/flags';
import useSelectedTheme from '@rsos/hooks/useSelectedTheme';
import { DARK_THEME, DARKER_THEME, LIGHT_THEME } from '@rsos/theme/constants';
import useHasCapability from './useHasCapability';
import useHasPermission from './useHasPermission';

/**
 * Get the theme name to switch to.
 * @returns String
 */
const useThemeToSwitchTo = () => {
  const hasUniteEventPaneCapabilities = useHasCapability(UNITE_EVENT_PANE);

  const hasUniteEventPanePermission = useHasPermission(
    UNITE_EVENT_PANE_PERMISSION,
  );

  const isUniteEventPaneEnabled = Boolean(
    useFlag('unite_eventPane') &&
      hasUniteEventPaneCapabilities?.hasCapability &&
      hasUniteEventPanePermission?.hasPermission,
  );

  const themeName = useSelectedTheme();

  return useMemo(() => {
    if (themeName === LIGHT_THEME) {
      return isUniteEventPaneEnabled ? DARKER_THEME : DARK_THEME;
    }

    return LIGHT_THEME;
  }, [isUniteEventPaneEnabled, themeName]);
};

export default useThemeToSwitchTo;
