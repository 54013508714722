import React, { useState, useRef, useEffect } from 'react';
import { Box, Button } from '@rsos/components/capstone/base';
import {
  AccordionWrapper,
  AccordionHeader,
  AccordionContentContainer,
} from './Accordion.styles';
import { getTriggerIconType } from './AccordionTriggerIconTypes';

function Accordion(props) {
  const {
    padding,
    margin,
    headerIcon,
    interactionType,
    headerIconAlign,
    headerPadding,
    headerAlign,
    background,
    noBorder,
    btnDisabled,
    btnSize,
    btnType,
    btnPx,
    height,
    width,
    header,
    stacked,
    content,
    isOpened,
    align,
    justify,
    style,
    callback = () => {},
  } = props;

  const [isLocalOpened, setIsLocalOpened] = useState(isOpened);
  const [setHeight, setHeightState] = useState('0px');

  const collapsibleRef = useRef(null);

  useEffect(() => {
    setHeightState(
      isLocalOpened ? `${collapsibleRef.current.scrollHeight}px` : '0px',
    );
  }, [isLocalOpened]);
  function toggleAccordion() {
    setIsLocalOpened(prev => !prev);
    callback();
  }

  return (
    <AccordionWrapper
      width={width}
      stacked={stacked}
      noBorder={noBorder}
      {...style}
    >
      <AccordionHeader
        onClick={interactionType === 'button' ? null : toggleAccordion}
        interactionType={interactionType}
        headerIconAlign={headerIconAlign}
        isOpened={isLocalOpened}
        headerPadding={headerPadding}
        noBorder={noBorder}
        align={headerAlign}
      >
        {header}
        <Box
          align={align || 'center'}
          justify={justify || 'center'}
          mr={headerIconAlign ? 12 : 0}
        >
          {interactionType === 'button' ? (
            <Button
              disabled={btnDisabled}
              onClick={toggleAccordion}
              btnType={btnType || 'tertiary'}
              size={btnSize || 'xxsmall'}
              type="button"
              px={btnPx}
            >
              {getTriggerIconType(headerIcon, isLocalOpened)}
            </Button>
          ) : (
            getTriggerIconType(headerIcon, isLocalOpened)
          )}
        </Box>
      </AccordionHeader>
      <AccordionContentContainer
        ref={collapsibleRef}
        maxHeight={setHeight}
        height={height}
        padding={padding}
        margin={margin}
        background={background}
      >
        {content}
      </AccordionContentContainer>
    </AccordionWrapper>
  );
}

export default Accordion;
