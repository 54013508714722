import { lightModeColorPalette } from './lightModeDefinition';

export const darkModeColorPalette = {
  transparent: 'transparent',
  primary_base: '#87ccea',
  primary_base_c2: '#daf2fd',
  primary_text: '#d9eaf8',
  secondary_link: '#acc2d5',
  secondary_text: '#96ADC2',
  heavy_line_outline: '#5B778D',
  light_line_divider: '#2c455d',
  highlights: '#004367',
  foreground: '#193651',
  background: '#0d2844',
  light_warning: '#fdf9d2',
  light_warning_text: '#051e37',
  heavy_warning: '#ffe8cb',
  light_danger: '#FF6F6F',
  heavy_danger: '#E46E6E',
  success: '#30E0AC',
  heavy_success: '#11B484',
  states: '#a2bbf1',
  pane_primary_text: '#30E0AC',
  unseen: '#d599ff',
  caution: '#FFF96C',
  tag_success_background: '#09434C',
  tag_caution_background: '#3B4535',
  tag_warning_background: '#4F3D31',
  tag_secondary_background: '#25384C',
};

export default {
  palette: darkModeColorPalette,
  fieldsToOverride: [
    {
      key: 'modal.scrim',
      value: darkModeColorPalette.secondary_text,
    },
    {
      key: 'mapCard.disclaimer.bg',
      value: lightModeColorPalette.light_warning,
    },
    {
      key: 'mapCard.disclaimer.floorsIcon',
      value: lightModeColorPalette.secondary_text,
    },
    {
      key: 'mapCard.disclaimer.primaryText',
      value: lightModeColorPalette.primary_text,
    },
    {
      key: 'mapCard.disclaimer.secondaryText',
      value: lightModeColorPalette.secondary_text,
    },
    {
      key: 'massDisasterToast.text',
      value: darkModeColorPalette.primary_text,
    },
    {
      key: 'trainIncidentAlert.iconColor',
      value: darkModeColorPalette.background,
    },
    {
      key: 'trainIncidentAlert.iconBg',
      value: '#FEDE8D',
    },
  ],
};
