import { createFetchRetryUsingProduct } from '@rsos/capstone';
import { instance } from '@rsos/sinatra';
import { checkStatus, parseJSON } from '@rsos/utils';
import { getAPIHost } from '@rsos/utils/metaTags';
import serializeRequestError from '@rsos/utils/serializeRequestError';

const baseURL = `${getAPIHost()}/v1/alerts/portal`;
export const getAlerts = async (orgName, queueMaxSize, endTime) => {
  const maxIterations = Math.ceil(queueMaxSize / 100);

  const query = async (limit, endTime) => {
    let url = `${baseURL}/${orgName}?limit=${limit}&extra=enrich_alert_status`;
    if (endTime) url += `&end=${endTime}`;
    return await instance.get(url, { headers: { retry: true } });
  };

  if (maxIterations <= 1) {
    return await query(queueMaxSize, endTime).then(res => res.data);
  } else {
    let currentIteration = 0,
      newEndTime = endTime,
      pages = [];
    while (currentIteration < maxIterations) {
      currentIteration += 1;

      // Calculate the `limit` to pass to the API request based on how many
      // remaining alerts to fetch and the queueMaxSize
      const remaining =
        queueMaxSize -
        pages.reduce((count, alertsPayload) => {
          return count + alertsPayload.alerts.length;
        }, 0);

      const getLimit = () => {
        const limit = 100;
        if (queueMaxSize > limit) {
          if (remaining > limit) {
            return limit;
          }
          return remaining;
        }
        return limit;
      };

      const limit = getLimit();
      await query(limit, newEndTime).then(res => {
        if (res.data.alerts.length) {
          newEndTime = res.data.alerts[0].created_time;
        }

        // Handle successful requests
        if (res.status === 200) {
          if (res.data.alerts.length < limit) {
            // End loop if the amount of alerts is less than the limit
            currentIteration += maxIterations;
          }
          pages.push(res.data);
        }
      });
    }
    return pages;
  }
};

/**
 * Query for all information about a specific alert
 * @param {string} orgName - name of the organization the user belongs to
 * @param {string} alertID - unique identifier of the alert to query the metadata of
 */
export const getAlert = async (orgName, alertID) => {
  return await instance.get(
    `${baseURL}/${orgName}/${alertID}?extra=enrich_alert_status`,
    {
      headers: { retry: true },
    },
  );
};

export const patchAlertStatus = info =>
  instance.patch(
    `${baseURL}/${info.org}/${info.alertID}`,
    {
      status: `${info.status}`,
      decline_reason: info.decline_reason ? `${info.decline_reason}` : '',
    },
    { headers: { retry: true } },
  );

/**
 * Get the status of a conversation. Possible returned values are
 * { status: `CONVERSATION_ENDED` AND `NOT_SET` }
 * @param {string} orgName - name of the organization the user belongs to
 * @param {string} conversationID - unique identifier of the alert to query the metadata of
 */
export const getAlertConversationStatus = async (orgName, conversationID) => {
  return await instance.get(
    `${baseURL}/${orgName}/conversations/${conversationID}/status`,
    {
      headers: { retry: true },
    },
  );
};

/**
 * Delete an alert's conversation
 * @param {string} orgName - name of the organization the user belongs to
 * @param {string} conversationID - unique identifier of the alert to query the metadata of
 */
export const deleteAlertConversation = async (orgName, conversationID) => {
  return await instance.delete(
    `${baseURL}/${orgName}/conversations/${conversationID}`,
    {
      headers: { retry: true },
    },
  );
};

/**
 * Fetches Alert ADR from RAD-E request
 * @async
 * @function getAlertAdr
 * @param {string} alertID
 * @param {string} token - For ICSP it is using access token while in RSP it needs product token
 * @param {boolean} withMeta - Whether or not to receive meta data in the response
 * @returns {object} - The parsed JSON response from promise
 */
export const getAlertAdr = async (alertID, token, withMeta) => {
  //NOTE: cannot use axios with the RAD E request because RAD E is using product token while
  // axios instance handling was for access token
  return await fetch(`${getAPIHost()}/v2/emergency-data/session`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: `alert://${alertID}`,
      meta: withMeta,
    }),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(async error => {
      const serializedError = await serializeRequestError(error);
      return Promise.reject(serializedError);
    });
};

/**
 * Fetches all available alert types for an org
 * @param {string} orgName
 * @returns
 */
export const getEmergencyTypes = async orgName => {
  return await instance.get(`${baseURL}/${orgName}/emergency-types`);
};

/**
 * Fetches all available alert types for an org
 * @param {string} orgName
 * @returns
 */
export const getAlertTypes = async orgName => {
  return await instance.get(`${baseURL}/${orgName}/alert-types`);
};

/**
 * Utilize an alert flow from the following REC organizations: rapidsosqa for RSP and Lucas_Test_CS for CSP
 * @param {string} callflow - name of the REC flow
 * @param {string} token - product token
 * @param {bool} blocking - if everything needs to stop for this (generally no)
 * @param {object} variables - object of what goes into the callflow
 * @returns response of api call
 */
export const postAlertFlow = async ({
  callflow,
  getState,
  dispatch,
  blocking,
  test_mode,
  variables,
  fetchConfig,
}) => {
  return await createFetchRetryUsingProduct({ getState, dispatch })(
    `${getAPIHost()}/v1/rem/trigger`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        callflow: callflow,
        blocking: blocking,
        test_mode: test_mode,
        variables: variables,
      }),
    },
    fetchConfig,
  ).then(parseJSON);
};
